<template>
  <div class="container-fluid px-0">
    <div class="row py-4" style="max-width: 1150px; margin: 0 auto">
      <div class="col-12 mb-3">
        <h3 class="text-left font-weight-bold text-uppercase">
          {{ name ? `Hola, ${name}` : "MI CUENTA" }}
        </h3>
        <small class="font-italic  text-uppercase">{{email}}</small>
      </div>

      <div class="w-100 py-2"></div>

      <b-modal ref="view-ticket">
        <div class="row">
          <div class="col">
            <h4 class="text-center mb-0">PEDIDO #{{ ticket.number }}</h4>
            <small class="text-uppercase text-center d-block">{{
              ticket.createdAt | moment("dddd DD/MM/YY")
            }}</small>
            <div class="text-center">
              <button
                class="btn btn-sm btn-link my-0 d-none"
                @click="make(ticket)"
              >
                VOLVER A PEDIR
              </button>
            </div>

            <div class="w-100 mt-3"></div>

            <strong>CLIENTE: </strong><span>{{ ticket.clientName }}</span>
            <div class="w-100"></div>
            <strong>TIPO: </strong
            ><span
              >{{ ticket.type }}
              <span v-if="ticket.type == 'Domicilio'"
                >- {{ ticket.address }}</span
              ></span
            >
            <div class="w-100"></div>
            <strong>TELEFONO: </strong><span>{{ ticket.phone }}</span>

            <div class="w-100 py-2"></div>
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(p, i) in ticket.products"
                :key="`p-${p._id}-${i}`"
              >
                <div class="row no-gutters">
                  <div class="col-10">
                    {{ p.qty }} -
                    <strong class="text-uppercase">{{ p.name }}</strong>
                  </div>
                  <div class="col text-right">
                    ${{ p.total | formatNumber }}
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row mb-2" v-if="ticket.type == 'Domicilio'">
                  <div class="col-3 ml-auto">
                    <strong>ENVIO</strong>
                  </div>
                  <div class="col-3 text-right">
                    <strong> ${{ ticket.deliveryValue | formatNumber }}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 ml-auto">
                    <strong>TOTAL</strong>
                  </div>
                  <div class="col-3 text-right">
                    <strong> ${{ ticket.total | formatNumber }}</strong>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>

      <div class="col-12">
        <b-overlay :show="loading">
          <span>MIS PEDIDOS ({{ total_items }})</span>
          <ul class="list-group">
            <li class="list-group-item" v-if="tickets.length == 0">
              <h4 class="text-center py-5">No tienes pedidos</h4>
            </li>
            <li
              class="list-group-item list-group-item-action click"
              v-for="ticket in tickets"
              :key="ticket._id"
              :class="{
                'list-group-item-danger': ticket.status == 'Cancelado',
                active: tid == ticket._id,
              }"
              @click="viewTicket(ticket)"
            >
              <div class="row align-items-center">
                <div class="col-12 col-md-5">
                  <div class="row no-gutters">
                    <div class="col-4">
                      <strong>#{{ ticket.number | formatNumber }}</strong>
                    </div>
                    <div class="col-8">
                      <small class="text-uppercase"
                        >{{ ticket.createdAt | moment("dddd, DD/MM/YY") }}
                        {{ ticket.createdAt | moment("HH:mm") }}</small
                      >
                    </div>
                    <div class="col-4">
                      <small class="text-uppercase mr-3">{{
                        ticket.type
                      }}</small>
                    </div>
                    <div class="col">
                      <strong class="text-uppercase">{{
                        ticket.clientName
                      }}</strong>
                    </div>
                  </div>

                  <small class="text-uppercase d-none">{{
                    ticket.business.custom_name || ticket.business.name
                  }}</small>
                </div>
                <div class="col-md-2 col-5 text-center">
                  <span
                    class="badge d-block py-1 text-uppercase border"
                    :class="{
                      'text-success border-success bg-white':
                        ticket.status == 'Aceptado',
                      'text-danger border-danger': ticket.status == 'Cancelado',
                    }"
                    >{{ ticket.status }}
                    {{ ticket.updatedAt | moment("HH:mm") }}</span
                  >
                  <small>{{ ticket.reason }}</small>
                </div>
                <div class="col-12 col-md-3 text-center">
                  <button
                    class="btn btn-block btn-sm btn-primary"
                    v-if="calificationAvailable(ticket) && !ticket.rating"
                  >
                    <small>CALIFICAR</small>
                  </button>
                  <div v-if="ticket.rating">
                    <b-form-rating
                      v-model="ticket.rating.note"
                      variant="warning"
                      readonly
                    />
                    <small class="font-italic">{{
                      ticket.rating.comment || "- - - -"
                    }}</small>
                  </div>
                </div>
                <div class="ml-auto col-md-2 col-5 text-right">
                  <small class="text-uppercase">{{ ticket.payment }}</small>
                  <div class="w-100"></div>
                  <strong>${{ getTotal(ticket) | formatNumber }}</strong>
                </div>

                <div class="col-12 d-none">
                  <div
                    class="row justify-content-center"
                    v-for="p in ticket.products"
                    :key="p._id"
                  >
                    <div class="col-7 text-uppercase">
                      <small>{{ p.qty }} - {{ p.name }}</small>
                      <small class="float-right"
                        >${{ p.total | formatNumber }}</small
                      >
                      <div class="w-100 border-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div class="">
            <b-pagination-nav
              :link-gen="linkGen"
              class="float-right mt-3"
              :number-of-pages="total_pages"
              exact-active-class="text-white"
              use-router
            ></b-pagination-nav>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rating: 0,
      ticket: {},
      tickets: [],
      page: 0,
      total_pages: 1,
      total_items: 0,
      loading: true,
      comment: "",
      tid: null,
      name: "",
      email: "",
      createdAt: null,
    };
  },
  mounted() {
    this.load();
    this.page = this.$route.query.page || 0;
  },
  methods: {
   
    make(ticket) {
      console.log(ticket.products);
      console.log("ticket", ticket);
    },
    viewTicket(ticket) {
      let token = ticket.createdAt.slice(-4);
      this.$router.push(this.getLink(`pedido/${ticket._id}?s=${token}`));
      // this.tid = ticket._id;
      // this.ticket = ticket;
      // this.$refs["view-ticket"].show();
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getTotal(ticket) {
      return ticket.products.reduce((t, c) => t + c.total, 0);
    },
    load() {
      let info = this.$store.state.info;
      let page = this.$route.query.page;
      let token = this.$cookies.get("token");
      this.loading = true;

      this.$http
        .get("account", {
          headers: { token },
        })
        .then(({ data }) => {
          this.name = data.data.name;
          this.email = data.data.email;
        }).catch((err) => {
         //if(err.data && err.data.details == 'Failed to authenticate token'){
            this.$cookies.remove('token')
            this.$router.push(this.getLink(''))
         // }
          console.log({err})
         //alert('Error')
        
        })
      this.$http
        .get(`account/ticket?bid=${info._id}&page=${page}`, {
          headers: { token },
        })
        .then((res) => {
          this.tickets = res.data.data;
          this.total_pages = res.data.pages;
          this.total_items = res.data.total_items;
        })
        .catch(() => {
          this.$noty.error("Error");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    info() {
      this.load();
    },
    $route: function () {
      this.load();
    },
  },
};
</script>